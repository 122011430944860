import React, { useState } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'react-scroll';
import logo from '../assets/solvenetics5.png'; // Update this path

const Navbar = () => {
    const [nav, setNav] = useState(false);

    return (
        <div className='fixed z-10 w-screen h-16 text-black bg-sky-50 drop-shadow-lg'>
            <div className='flex items-center justify-between w-full h-full max-w-6xl px-2 mx-auto'>
                <div className='flex items-center'>
                    <Link to="home" smooth={true} offset={0} duration={500}>
                        <img src={logo} alt="Logo" className='w-32 cursor-pointer' /> {/* Adjust width as needed */}
                    </Link>
                    <ul className='hidden md:flex'>
                        <li className='px-3 py-1 cursor-pointer hover:underline decoration-2 decoration-sky-700'>
                            <Link to="home" smooth={true} offset={0} duration={500}>Home</Link>
                        </li>
                        <li className='px-3 py-1 cursor-pointer hover:underline decoration-2 decoration-sky-700'>
                            <Link to="about" smooth={true} offset={-140} duration={500}>About</Link>
                        </li>
                        <li className='px-3 py-1 cursor-pointer hover:underline decoration-2 decoration-sky-700'>
                            <Link to="support" smooth={true} offset={0} duration={500}>Support</Link>
                        </li>
                        <li className='px-3 py-1 cursor-pointer hover:underline decoration-2 decoration-sky-700'>
                            <Link to="platforms" smooth={true} offset={-100} duration={500}>Platforms</Link>
                        </li>
                    </ul>
                </div>
                <div className='md:hidden' onClick={() => setNav(!nav)}>
                    {!nav ? <MenuIcon className='cursor-pointer w-9' /> : <XIcon className='cursor-pointer w-9' />}
                </div>
            </div>
            <ul className={!nav ? 'hidden' : 'absolute w-full px-8 text-gray-800 bg-sky-100'}>
                <li className='px-6 py-4 cursor-pointer hover:bg-sky-200'>
                    <Link onClick={() => setNav(!nav)} to="home" smooth={true} offset={0} duration={500}>Home</Link>
                </li>
                <li className='px-6 py-4 border-sky-200 cursor-pointer hover:bg-sky-200 border-y-2'>
                    <Link onClick={() => setNav(!nav)} to="about" smooth={true} offset={-140} duration={500}>About</Link>
                </li>
                <li className='px-6 py-4 cursor-pointer hover:bg-sky-200'>
                    <Link onClick={() => setNav(!nav)} to="support" smooth={true} offset={0} duration={500}>Support</Link>
                </li>
                <li className='px-6 py-4 border-sky-200 cursor-pointer hover:bg-sky-200 border-y-2'>
                    <Link onClick={() => setNav(!nav)} to="platforms" smooth={true} offset={-100} duration={500}>Platforms</Link>
                </li>
                <li className='px-6 py-4 cursor-pointer hover:bg-sky-200'>
                    <Link onClick={() => setNav(!nav)} to="pricing" smooth={true} offset={10} duration={500}>Pricing</Link>
                </li>
            </ul>
        </div>
    );
}

export default Navbar;