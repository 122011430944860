import React, { useState } from "react";
import FooterBlock from "./FooterBlock";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import PrivacyPolicyModal from "./PrivacyPolicyModal"; // Ensure you import the modal component

const Footer = () => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  const handlePrivacyClick = () => {
    setIsPrivacyModalOpen(true); // Open the Privacy Policy modal
  };

  return (
    <div className="w-full px-4 py-8 mt-24 text-gray-300 bg-gray-900">
      <div className="grid max-w-6xl grid-cols-2 gap-4 py-8 mx-auto border-b-2 border-gray-600 md:grid-cols-6">
        {/* <FooterBlock
          head="Solutions"
          li1="Marketing"
          li2="Analytics"
          li3="Commerce"
          li4="Data"
          li5="Cloud"
        />
        <FooterBlock
          head="Support"
          li1="Pricing"
          li2="Documentation"
          li3="Guides"
          li4="Api Status"
        />
        <FooterBlock
          head="Company"
          li1="About"
          li2="Blog"
          li3="Jobs"
          li4="Press"
          li5="Partners"
        /> */}
        <FooterBlock
          head="Legal"
          // li1="Claims"
          li2={
            <button
              onClick={handlePrivacyClick}
              className="bg-transparent border-none outline-none shadow-none p-0 m-0 text-inherit cursor-pointer focus:outline-none active:bg-transparent active:text-inherit"
            >
              Privacy Policy
            </button>
          }
          // li3="Terms"
          // li4="Policies"
          // li5="Conditions"
        />

        {/* Commented out the Newsletter Subscription Section */}
        {/*
        <div className="col-span-2 pt-8 md:pt-0">
          <h1 className="text-xl font-bold text-gray-200 uppercase">
            Subscribe to Our Newsletter
          </h1>
          <p className="pt-2 pb-4 text-gray-400">
            The latest news, articles, and resources sent to your inbox weekly.
          </p>
          <div className="flex">
            <input
              type="email"
              placeholder="Enter your Email"
              className="w-full px-2 text-black rounded-md"
            />
            <button className="px-2 py-2 ml-2 active:bg-sky-300 hover:bg-sky-200">
              Subscribe!
            </button>
          </div>
        </div>
        */}

        {/* Adding Contact Information */}
        <div className="col-span-2 pt-8 md:pt-0">
          <h3 className="py-2 font-bold uppercase">Contact Us</h3>
          <ul className="pt-2 text-gray-400">
            <li className="mb-2">
              <strong>Email:</strong>{" "}
              <a href="mailto:info@solvenetics.com" className="text-sky-500 hover:underline">
                info@solvenetics.com
              </a>
            </li>
            <li className="mb-2">
              <strong>Phone:</strong>{" "}
              <a href="tel:+923459238111" className= "text-sky-500 hover:underline">
                +92 345 9238111
              </a>
            </li>
            <li className="mb-2">
              <strong>Address:</strong> Karachi, Pakistan
            </li>
          </ul>
        </div>
      </div>

      <div className="grid justify-between max-w-6xl py-4 mx-auto text-sky-500 sm:flex">
        <div>
          <p>{currentYear} Solve Netics. All rights reserved.</p>
        </div>
        <div className="flex my-4 sm:my-0">
          <a
            href="https://www.facebook.com/solvenetics"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="mx-2 cursor-pointer hover:text-sky-600" size={30} />
          </a>
          <a
            href="https://www.instagram.com/solvenetics"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="mx-2 cursor-pointer hover:text-sky-600" size={30} />
          </a>
          <a
            href="https://x.com/solvenetics"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="mx-2 cursor-pointer hover:text-sky-600" size={30} />
          </a>
          <a
            href="https://www.linkedin.com/company/solve-netics"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="mx-2 cursor-pointer hover:text-sky-600" size={30} />
          </a>
        </div>
      </div>
      <PrivacyPolicyModal
        isOpen={isPrivacyModalOpen}
        onClose={() => setIsPrivacyModalOpen(false)}
      />
    </div>
  );
};

export default Footer;