import React from "react";

const PrivacyPolicyModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
     <div className="bg-gray-100 min-h-screen py-10 flex flex-col">
      <div className="container mx-auto px-4 flex-1 mt-10">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Privacy Policy</h1>
        <p className="text-gray-700 text-sm mb-2">Last Updated: January 1, 2024</p>
        <p className="text-gray-700 mb-4">
          The website ("Site") for Solve Netics (“Solve Netics”, “We”, “Our”, “Us” or “Company”) was created to provide software development services (the "Services").
        </p>
        <p className="text-gray-700 mb-4">
          The privacy of our Site visitors ("you," "your," or "user") is important to us, and in order to protect your personal information, we have implemented the following Privacy Policy with provisions that apply to the collection of data by Solve Netics, its subsidiaries, and its affiliates.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Updates and Changes to the Privacy Policy</h2>
        <p className="text-gray-700 mb-4">
          In the event of a change in this policy, a revised Privacy Policy will be posted to our Website, and the “Updated” date will be changed. If the revised Privacy Policy contains a material change to how we collect or use personal information, notice of the change will be emailed to you or posted on the Website’s home page.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Information We Collect</h2>
        <p className="text-gray-700 mb-4">
          We are required by law, regulation, or securities reasons to ask for certain personal information to provide our Services to you. Solve Netics makes commercially reasonable efforts to maintain the confidentiality, integrity, and security of our clients’ personal information.
        </p>

        <ul className="list-disc list-inside mb-4 text-gray-700">
          <li>Computer or mobile device information</li>
          <li>Website from which our domain is accessed and website usage information</li>
          <li>Operating system of your computer</li>
          <li>Country from which our website is accessed</li>
          <li>Name of your internet provider</li>
          <li>Name and URL of the Data accessed</li>
          <li>Date and time of access</li>
          <li>IP address of the accessing computer</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">How and Why We Gather Information</h2>
        <p className="text-gray-700 mb-4">
          When you register for an account for the Services or at any later time, we collect certain personal information from you to open an account, transact business, communicate with you, verify your identity, and fulfill legal and regulatory requirements. We call this your “Profile.”
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Cookies and Other Technologies</h2>
        <p className="text-gray-700 mb-4">
          When you visit the Website, whether or not you register for an account, we may send one or more cookies. “Cookies” are small text files containing a string of alphanumeric characters that may be placed on your web browser.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Social Media Services</h2>
        <p className="text-gray-700 mb-4">
          You can log in to the Website using social media services such as Google. These services will authenticate your identity and provide you the option to share certain personal information with us.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Notifications and Communications</h2>
        <p className="text-gray-700 mb-4">
          Solve Netics will send you email notifications from time to time. Some notifications are required elements of your transactions on our platform, such as confirmations of particular actions you have taken.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Opt-out Policy</h2>
        <p className="text-gray-700 mb-4">
          We may at times send you email communications with marketing or promotional materials. If you prefer not to receive such marketing or promotional emails from us, you may unsubscribe at any time.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Information Security</h2>
        <p className="text-gray-700 mb-4">
          We use commercially reasonable security technologies and procedures to help protect your personal information from unauthorized access, use or disclosure. However, we cannot guarantee the complete safety of your information. It is your responsibility to keep your information confidential.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Contact Information</h2>
        <p className="text-gray-700 mb-4">
          Please contact Solve Netics with any questions or comments about this Privacy Policy, your personal information, or your choices regarding our collection and use of your personal information. You can email us at{" "}
          <a href="mailto:info@solvenetics.com" className="text-sky-500 hover:underline">
            businessdevelopment@solvenetics.com
          </a>.
        </p>
      </div>
      <div className="container mx-auto px-4 py-4">
        <button
          onClick={onClose}
          className="block mx-auto px-4 py-2"
        >
          Go Back to Home
        </button>
      </div>
    </div>
    </div>
  );
};
export default PrivacyPolicyModal;